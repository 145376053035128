import {
  GreyedButton,
  Particles,
  PrimaryButton,
  random,
} from "@storytime/shared/build/components";
import { config } from "@storytime/shared/build/config";
import { useLottie } from "@storytime/shared/build/hooks";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import useWindowSize from "react-use/lib/useWindowSize";

const { random: randomNumber } = Math;

const PARTICLE_COLORS = [
  "#5d5273",
  "#f8656a",
  "#92e8c9",
  "#ffc2bd",
  "#dcab7c",
  "#bd72f2",
  "#ff9e7c",
  "#badae4",
  "#f7eaa2",
];

// tslint:disable-next-line:max-func-body-length
export default function Home() {
  const { t } = useTranslation();

  const { width } = useWindowSize();

  const logoAnimation = useLottie(
    {
      path: "/assets/logo-full.json",
    },
    [
      [0, 409],
      [190, 409],
    ],
  );

  const [showLogoParticles, setShowLogoParticles] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLogoParticles(true);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const stepOneRef = useLottie({
    path: "/assets/step_01.json",
  });

  const stepTwoRef = useLottie({
    path: "/assets/step_02.json",
  });

  const stepThreeRef = useLottie({
    path: "/assets/step_03.json",
  });

  let particleWidth;
  if (width < 900) {
    particleWidth = width;
  } else if (width < 1200) {
    particleWidth = 776;
  } else {
    particleWidth = 968;
  }
  const tenthParticleWidth = particleWidth / 10;
  const halfParticleWidth = particleWidth / 2;

  const particleHeight = particleWidth * 0.361570248;
  const tenthParticleHeight = particleHeight / 10;
  const halfParticleHeight = particleHeight / 2;

  return (
    <div>
      <div className="panel --hero">
        <div className="hero_wrapper">
          <div className="hero_logo">
            {showLogoParticles ? (
              <Particles
                className="hero_particles"
                width={particleWidth}
                height={particleHeight}
                emitters={[
                  {
                    initialSpawn: 0,
                    wander: 0.5,
                    frequency: 0.15,
                    initialVelocity: random.numberPair([-20, 20], [-50, 50]),
                    location: random.numberPair(
                      [
                        halfParticleWidth - tenthParticleWidth * 3,
                        halfParticleWidth + tenthParticleWidth * 3,
                      ],
                      [
                        halfParticleHeight - tenthParticleHeight,
                        halfParticleHeight + tenthParticleHeight,
                      ],
                    ),
                    acceleration: () => [0, 0],
                    colors: PARTICLE_COLORS,
                    startDyingAt: 0.1,
                    spawnUntil: random.number(0.3, 0.6),
                    lifetime: random.number(2, 2),
                    radius: random.number(3, 5),
                    friction: 0,
                  },
                ]}
              />
            ) : (
              undefined
            )}

            <div ref={logoAnimation} />
          </div>
          <h1 className="hide-text">{t("homepage.my_storytime")}</h1>
        </div>
        <div className="hero_copy_container">
          <h2 className="medium-copy hero_headline">
            {t("homepage.hero.headline_tell_stories")}
          </h2>
          <p className="small-copy reversed-small-copy hero_copy">
            {t("homepage.hero.subheadline_pre-record_stories")}
          </p>
          <PrimaryButton href={config.urls.app}>
            {t("homepage.hero.start")}
          </PrimaryButton>
        </div>
        <a
          href="#main-content"
          className="hero_down_arrow"
          aria-label="scroll to main content"
        >
          <svg role="presentation" viewBox="0 0 24 24">
            <path
              fill="#ffffff"
              d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
            />
            <path fill="none" d="M0 0h24v24H0V0z" />
          </svg>
        </a>
        <div className="hero_badge_container">
          <div className="hero_badge">
            {t("general.voice_experiment_label")}
            <a
              href="https://www.instrument.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="badge --instrument"
                src="assets/Logo_Instrument.svg"
                alt={t("general.instrument_badge_alt_text")}
              />
            </a>
          </div>
          <div className="hero_badge">
            {t("general.made_with_google_label")}
            {/* tslint:disable-next-line: react-a11y-anchors */}
            <a
              href="https://experiments.withgoogle.com/collection/voice"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="badge --google"
                src="assets/Logo_Google.svg"
                alt={t("general.google_badge_alt_text")}
              />
            </a>
          </div>
        </div>
      </div>
      <div id="main-content" className="panel --setup">
        <h3 className="large-copy">{t("homepage.setup.how_it_works")}</h3>
        <ol className="setup__steps">
          <li className="setup__step setup__step-one">
            <div className="setup__step-image" ref={stepOneRef} />
            <h4 className="setup__step-heading">
              {t("homepage.setup.step_one.title")}
            </h4>
            <p className="setup__step-copy">
              {t("homepage.setup.step_one.copy")}
            </p>
          </li>

          <li className="setup__step setup__step-two">
            <div className="setup__step-image" ref={stepTwoRef} />
            <h4 className="setup__step-heading">
              {t("homepage.setup.step_two.title")}
            </h4>
            <p className="setup__step-copy">
              {t("homepage.setup.step_two.copy")}
            </p>
          </li>

          <li className="setup__step setup__step-three">
            <div className="setup__step-image" ref={stepThreeRef} />
            <h4 className="setup__step-heading">
              {t("homepage.setup.step_three.title")}
            </h4>
            <p className="setup__step-copy">
              {t("homepage.setup.step_three.copy")}
            </p>
          </li>
        </ol>
        <GreyedButton linkTo="/faq#">
          {t("homepage.setup.how_it_works")}
        </GreyedButton>
      </div>
      <div className="panel --how-to">
        {width >= 768 ? (
          <Particles
            className="how-to_particles"
            width={particleWidth}
            height={particleHeight}
            emitters={[
              {
                initialSpawn: 10,
                wander: 0,
                frequency: 0.15,
                initialVelocity: random.numberPair([-75, 75], [-150, -10]),
                location: random.numberPair(
                  [
                    halfParticleWidth - tenthParticleWidth * 3.5,
                    halfParticleWidth + tenthParticleWidth * 3.5,
                  ],
                  [particleHeight, particleHeight - halfParticleHeight],
                ),
                acceleration: () => [randomNumber() > 0.5 ? 1 : -1, 0],
                colors: PARTICLE_COLORS,
                startDyingAt: 0.1,
                spawnUntil: random.number(0.3, 0.6),
                lifetime: random.number(3, 6),
                radius: random.number(3, 6),
                friction: 1,
              },
            ]}
          />
        ) : (
          undefined
        )}

        <p className="small-copy reversed-small-copy how-to_copy">
          {t("homepage.how_to.for_others_to_hear")}
        </p>
        <h3 className="large-copy how-to_headline">
          {t("homepage.how_to.hey_google")}
        </h3>
        <img
          className="google-home-image"
          {...{ loading: "lazy" }}
          src="assets/Google_Home_compressed.png"
          width="416"
          height="327"
          alt={t("homepage.how_to.google_home_alt_text")}
        />
      </div>
      <div className="panel --inspo">
        <h3 className="large-copy inspo_headline">
          {t("homepage.inspiration.inspired_by")}
        </h3>
        <div className="small-copy inspo_copy">
          <ReactMarkdown
            source={t("homepage.inspiration.inspiration_copy")}
            escapeHtml={false}
            linkTarget="_blank"
          />
        </div>

        {/* tslint:disable react-iframe-missing-sandbox */}
        <div className="youtube-wrapper">
          <iframe
            {...{ loading: "lazy" }}
            className="youtube-embed"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/_4UTQy1GaYQ"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
            title={t("homepage.video.title")}
          />
        </div>
        {/* tslint:enable react-iframe-missing-sandbox */}
      </div>
    </div>
  );
}
