import { PrimaryButton } from "@storytime/shared/build/components";
import { config } from "@storytime/shared/build/config";
import Faq from "@storytime/shared/build/routes/Faq";
import React, { Component, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { HeaderLogo } from "./HeaderLogo";
import Home from "./Home";
import "./i18n";
import Privacy from "./Privacy";
import TermsOfService from "./TermsOfService";

// tslint:disable-next-line:no-empty-interface
interface Props extends WithTranslation {}

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
}

class App extends Component<Props> {
  render() {
    const { t } = this.props;

    return (
      <div>
        <Helmet>
          <title>{t(`homepage.my_storytime`)}</title>
          <meta property="og:title" content={t(`homepage.my_storytime`)} />
          <meta
            property="og:description"
            content={t(`homepage.page_description`)}
          />
        </Helmet>
        <Router>
          <ScrollToTop />
          <nav>
            <NavLink className="nav-link --home" to="/">
              <HeaderLogo />
              <span className="hide-text">{t("homepage.nav.home")}</span>
            </NavLink>
            <div>
              <NavLink
                activeClassName="--active"
                className="nav-link --faq"
                to="/faq#"
              >
                {t("homepage.nav.faqs")}
              </NavLink>
              <PrimaryButton href={config.urls.app}>
                {t("homepage.hero.start")}
              </PrimaryButton>
            </div>
          </nav>
          <Route exact={true} path="/" component={Home} />
          <Route exact={true} path="/faq" component={Faq} />
          <Route exact={true} path="/tos" component={TermsOfService} />
          <Route exact={true} path="/privacy" component={Privacy} />
          <div className="footer">
            <div className="footer_badge_container">
              <div className="footer_badge">
                {t("general.voice_experiment_label")}
                <a
                  href="https://www.instrument.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="badge --instrument"
                    src="/assets/Logo_Instrument.svg"
                    alt={t("general.instrument_badge_alt_text")}
                  />
                </a>
              </div>
              <div className="footer_badge">
                {t("general.made_with_google_label")}
                {/* tslint:disable-next-line: react-a11y-anchors */}
                <a
                  href="https://experiments.withgoogle.com/collection/voice"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="badge --google"
                    src="/assets/Logo_Google.svg"
                    alt={t("general.google_badge_alt_text")}
                  />
                </a>
              </div>
            </div>
            <ul className="footer-links-container">
              <li className="footer-item">
                <NavLink className="footer-link" to="/privacy">
                  {t("homepage.nav.privacy")}
                </NavLink>
              </li>
              <li className="footer-item">
                <NavLink className="footer-link" to="/tos">
                  {t("homepage.nav.terms")}
                </NavLink>
              </li>
            </ul>
          </div>
        </Router>
      </div>
    );
  }
}

export default withTranslation()(App);
