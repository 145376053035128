import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import "./TermsOfService.css";

export default function TermsOfService() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="TermsOfService">
      <ReactMarkdown
        source={t("terms")}
        escapeHtml={false}
        linkTarget="_blank"
      />
    </div>
  );
}
