import i18next from "i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
// import esJson from "./es.json";
import raw from "raw.macro";
import { initReactI18next } from "react-i18next";
import enJson from "./en.json";

(enJson.translation as any).privacy = raw("./privacy.en.md");
(enJson.translation as any).terms = raw("./terms.en.md");

i18next
  .use(i18nextBrowserLanguagedetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["navigator"],
    },

    whitelist: ["en"], // , "es"],

    lng: "en",
    fallbackLng: "en",

    debug: !["test", "production"].includes(process.env.NODE_ENV || ""),

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      defaultTransParent: "div", // needed for preact
      wait: true,
    },

    resources: {
      en: enJson,
      // es: esJson,
    },
  });

export default i18next;
