import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import "./Privacy.css";

export default function Privacy() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Privacy">
      <ReactMarkdown
        source={t("privacy")}
        escapeHtml={false}
        linkTarget="_blank"
      />
    </div>
  );
}
