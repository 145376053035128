import React from "react";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// tslint:disable-next-line:no-var-requires
const { render } = require("react-snapshot");

render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
